import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from '@blueprism/nextgen-api';
import { useToast, ToastType } from '@blueprism/ui-pattern-toasts';

import { useRegionContext } from 'services/region';
import { QueryKeys } from 'types/queries';

import { useProductActivationDrawerContext } from '../../context';
import { formatDateToString } from '../../utils';

import { ACTIVATE_SKU } from './api';

import type { ActivateProductFormData } from '../../types';
import type { MeterData } from './api/types';
import type { FormikHelpers } from 'formik';
import type { ActivateSkuVariables, ActivateSkuResponse } from './api';

export function useActivateSkuApi(tenantId: string, concurrencyStamp: ActivateSkuVariables['concurrencyStamp']) {
  const { requestWithPrefix } = useApiClient();
  const queryClient = useQueryClient();
  const { managementRegionPrefix } = useRegionContext();
  const { formatMessage } = useIntl();
  const { triggerToast } = useToast();

  const { closeDrawer, drawerState, productConfigurationPage } = useProductActivationDrawerContext();

  const { mutateAsync } = useMutation({
    mutationFn: (variables: ActivateSkuVariables) => {
      return requestWithPrefix<ActivateSkuResponse>(managementRegionPrefix, ACTIVATE_SKU, variables);
    },
  });

  async function handleSubmit(
    { sku, startDate, endDate, ...meters }: Partial<ActivateProductFormData>,
    actions: FormikHelpers<ActivateProductFormData>,
  ) {
    if (!tenantId || !sku?.id || !drawerState?.id) return;

    const metersPayload: MeterData[] = Object.entries(meters).map(([key, value]) => ({
      identifier: key,
      limit: Number(value),
    }));

    try {
      await mutateAsync({
        tenantId,
        concurrencyStamp,
        skuCode: sku.id,
        meterData: metersPayload,
        productId: drawerState.id,
        from: formatDateToString(startDate),
        to: formatDateToString(endDate),
      });

      triggerToast({
        type: ToastType.SUCCESS,
        title: productConfigurationPage
          ? formatMessage({ id: 'account.productConfiguration.notification.skuAdded' })
          : formatMessage({ id: 'account.products.productsActivated' }),
        description: productConfigurationPage
          ? formatMessage({ id: 'account.productConfiguration.notification.skuAdded.message' })
          : formatMessage({ id: 'account.products.productsActivated.message' }, { displayName: drawerState.name }),
      });

      queryClient.invalidateQueries({ queryKey: [QueryKeys.GetTenantProducts, tenantId, managementRegionPrefix] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GetActivatedSkusByProduct] });
      queryClient.invalidateQueries({ queryKey: [QueryKeys.GetActivatedProduct] });
    } catch (error) {
      triggerToast({
        type: ToastType.ALERT,
        title: productConfigurationPage
          ? formatMessage({ id: 'account.productConfiguration.notification.addingSkuFailed' })
          : formatMessage({ id: 'account.products.productsActivationFailed' }),
        description: productConfigurationPage
          ? formatMessage({ id: 'account.productConfiguration.notification.addingSkuFailed.message' })
          : formatMessage(
              { id: 'account.products.productsActivationFailed.message' },
              { displayName: drawerState.name },
            ),
      });
    } finally {
      actions.resetForm();
      closeDrawer();
    }
  }

  return { handleSubmit };
}
