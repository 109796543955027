import { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ToolbarButton } from '@blueprism/ui-core';
import { Plus } from '@blueprism/ui-icons';
import { useFormatDate } from '@blueprism/nextgen-localisation';
import { Table, useTable } from '@blueprism/nextgen-platform-table';
import type { TableColumns, CheckboxOption } from '@blueprism/nextgen-platform-table';

import { Routes } from 'routes';
import { useAccountMultiRegionRouting, useTenantListApi } from 'hooks';
import { useRegionContext } from 'services/region';

import {
  INITIAL_SORTING_OPTIONS,
  INITIAL_TENANT_LIST_FILTER_STATE,
  SESSION_STORAGE_TABLE_ID_KEY,
  StatusOptions,
} from './constants';

import type { TenantListColumns } from 'hooks/useTenantListApi/api';

export function TenantListView() {
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  const { goToAccountDetails } = useAccountMultiRegionRouting();

  const { formatDate } = useFormatDate();

  const { selectedManagementRegion } = useRegionContext();

  function handleAddAccountClick() {
    push(Routes.AccountAdd);
  }

  const tableColumns: TableColumns<TenantListColumns> = {
    name: {
      order: 1,
      header: formatMessage({ id: 'account.table.tenantName' }),
      width: '20%',
    },
    id: {
      order: 2,
      header: formatMessage({ id: 'account.table.tenantId' }),
      width: '40%',
    },
    status: {
      order: 3,
      header: formatMessage({ id: 'account.table.status' }),
      tableCell: (status) => formatMessage({ id: `account.data.status.${status}` }),
      width: '15%',
    },
    dateCreated: {
      order: 4,
      header: formatMessage({ id: 'account.table.dateCreated' }),
      tableCell: (dateCreated) => formatDate(dateCreated),
      width: '15%',
    },
  };

  const { queryVariables, createPagingOptions, values, resetSelectedRowIds, ...restTableProps } = useTable({
    tableConfig: {
      columns: tableColumns,
      selectRows: true,
      defaultSortingOptions: INITIAL_SORTING_OPTIONS,
    },
    toolbarConfig: {
      customContextualButtons: [
        <ToolbarButton
          key={1}
          onClick={handleAddAccountClick}
          label={formatMessage({ id: 'account.create.button' })}
          icon={<Plus />}
        />,
      ],
    },
    emptyStateConfig: {
      emptyMessage: 'account.message.noAccounts',
    },
    filtersConfig: {
      tableId: SESSION_STORAGE_TABLE_ID_KEY,
      initialFilterValues: INITIAL_TENANT_LIST_FILTER_STATE,
    },
  });

  const variables = useMemo(() => {
    const managementRegionFilter = {
      managementLocation: { eq: selectedManagementRegion?.id },
    };

    const filters = (queryVariables?.where?.and || []) as unknown[];

    return {
      ...queryVariables,
      where: { and: [managementRegionFilter, ...filters] },
    };
  }, [queryVariables, selectedManagementRegion]);

  const { isLoading, pagingOptions, listData } = useTenantListApi({
    createPagingOptions,
    onCompleted: resetSelectedRowIds,
    enabled: !!selectedManagementRegion?.id,
    variables,
  });

  const translateStatusOptions: CheckboxOption[] = StatusOptions.map((option) => ({
    ...option,
    label: formatMessage({ id: option.label }),
  }));

  return (
    <Table
      isLoading={isLoading}
      data={listData}
      rowId="id"
      pagingOptions={pagingOptions}
      onView={({ id }) => goToAccountDetails(id)}
      {...restTableProps}
      filterInputs={[
        {
          type: 'text',
          name: 'name',
          label: formatMessage({ id: 'account.table.tenantName' }),
          value: values!.name,
        },
        {
          type: 'checkbox',
          name: 'status',
          label: formatMessage({ id: 'account.table.status' }),
          value: values!.status,
          options: translateStatusOptions,
        },
      ]}
    />
  );
}
