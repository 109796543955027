import { useRef } from 'react';

import { useParams } from 'react-router-dom';

import { Stack } from '@blueprism/ui-core';
import { Form } from '@blueprism/nextgen-platform-components';

import { useActivatedProductApi } from 'pages/ProductConfiguration/hooks/';

import { useActivateSkuApi } from '../../hooks';
import { initialValues } from '../../constants';
import { ProductActivationDrawer } from '../ProductActivationDrawer';

import { ProductActivationFormContent } from './ProductActivationFormContent';

import type { ProductConfigurationParams } from 'pages/ProductConfiguration/types/';
import type { ActivateProductFormData } from '../../types';
import type { FormikProps } from 'formik';

export function ProductActivationForm() {
  const { accountId, productId } = useParams<ProductConfigurationParams>();
  const formikRef = useRef<FormikProps<ActivateProductFormData>>(null);
  const { concurrencyStamp = '' } = useActivatedProductApi(accountId, productId);
  const { handleSubmit } = useActivateSkuApi(accountId, concurrencyStamp);

  return (
    <ProductActivationDrawer formikRef={formikRef}>
      <Stack padding="medium" gap="base">
        <Form initialValues={initialValues} onSubmit={handleSubmit} ref={formikRef}>
          <ProductActivationFormContent />
        </Form>
      </Stack>
    </ProductActivationDrawer>
  );
}
