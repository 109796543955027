import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Placeholder, Stack } from '@blueprism/ui-core';
import { NoContent } from '@blueprism/ui-layouts';
import { Details } from '@blueprism/ui-icons';

import { useAccountMultiRegionRouting } from 'hooks';
import { useProductActivationDrawerContext } from 'components';

import { ProductCard, ProductGroup } from './components';
import { useProductsApi } from './hooks';

import type { AccountDetailsParams } from 'types';

export function Products() {
  const { formatMessage } = useIntl();
  const { accountId } = useParams<AccountDetailsParams>();
  const { goToproductSkusListPage } = useAccountMultiRegionRouting();

  const { openDrawer } = useProductActivationDrawerContext();

  const { isLoading, activatedProducts, notActivatedProducts } = useProductsApi(accountId);

  const isActiveProductsExists = activatedProducts.length > 0;
  const isNotActiveProductsExists = notActivatedProducts.length > 0;

  const activeProductCards = activatedProducts.map(({ name, id }) => {
    function handleOnClick() {
      goToproductSkusListPage(accountId, id);
    }

    return (
      <ProductCard
        onClick={handleOnClick}
        label={name}
        buttonLabel="account.products.availableProducts.configure.button.label"
        key={name}
      />
    );
  });

  const notActiveProductCards = notActivatedProducts.map(({ name, id }) => {
    function handleOnClick() {
      openDrawer({ name, id });
    }

    return (
      <ProductCard
        onClick={handleOnClick}
        label={name}
        buttonLabel="account.products.availableProducts.activate.button.label"
        key={name}
      />
    );
  });

  return (
    <Stack gap="medium">
      {isLoading ? (
        <Stack gap="small" width="30rem">
          <Placeholder height="2.25rem" />
          <Placeholder height="10rem" />
        </Stack>
      ) : (
        <>
          {isActiveProductsExists && (
            <ProductGroup title={formatMessage({ id: 'account.products.activeProducts.header' })}>
              {activeProductCards}
            </ProductGroup>
          )}

          {isNotActiveProductsExists && (
            <ProductGroup title={formatMessage({ id: 'account.products.availableProducts.header' })}>
              {notActiveProductCards}
            </ProductGroup>
          )}

          {!isActiveProductsExists && !isNotActiveProductsExists && (
            <NoContent icon={<Details />} title={formatMessage({ id: 'account.products.noContent.title' })} />
          )}
        </>
      )}
    </Stack>
  );
}
