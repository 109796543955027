import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ApiErrorBoundary } from '@blueprism/nextgen-api';
import { useBreadcrumbs } from '@blueprism/nextgen-shared-hooks';
import { PageLayout } from '@blueprism/ui-layouts';
import { Text, Breadcrumbs, Link } from '@blueprism/ui-core';

import { Routes } from 'routes';
import { useRegionContext } from 'services/region';
import { useTenantNameApi } from 'pages/Account/hooks';
import { ProductActivationFormDrawer, StyledPlaceholder, StyledTitleText } from 'components';

import { useActivatedProductApi } from './hooks';
import { ProductConfigurationView } from './ProductConfigurationView';

import type { ProductConfigurationParams } from './types';

export function ProductConfigurationPage() {
  const { formatMessage } = useIntl();
  const { accountId, productId } = useParams<ProductConfigurationParams>();
  const { selectedManagementRegion } = useRegionContext();

  const { tenantName = '', isLoading } = useTenantNameApi(accountId);
  const { productName, isProductLoading } = useActivatedProductApi(accountId, productId);

  const breadCrumbLinks = [
    { href: Routes.Account, title: formatMessage({ id: 'navigation.account.label' }) },
    {
      href: Routes.getAccountDetailsUrl(selectedManagementRegion?.id, accountId),
      title: `${tenantName}`,
      dependOnLoading: true,
    },
    { title: productName, dependOnLoading: true },
  ];
  const { breadcrumbs } = useBreadcrumbs(breadCrumbLinks, isLoading || isProductLoading);

  return (
    <PageLayout
      breadcrumbs={
        <Breadcrumbs ariaLabel={formatMessage({ id: 'navigation.breadcrumbs.productConfiguration.ariaLabel' })}>
          {breadcrumbs.map(({ title, navigable, ...linkProps }) =>
            navigable ? (
              <Link {...linkProps} key={title}>
                {title}
              </Link>
            ) : (
              <Text key={title}>{title}</Text>
            ),
          )}
        </Breadcrumbs>
      }
      pageTitle={
        isProductLoading ? (
          <StyledPlaceholder width="16rem" height="3rem" />
        ) : (
          <StyledTitleText type="h3">{productName}</StyledTitleText>
        )
      }
      pageContent={
        <ApiErrorBoundary>
          <ProductActivationFormDrawer productConfigurationPage>
            <ProductConfigurationView name={productName} />
          </ProductActivationFormDrawer>
        </ApiErrorBoundary>
      }
    />
  );
}
